import React from 'react';

interface Props {
    color?: string;
    height?: number;
    width?: number;
}

const AInteraction :React.FC<Props> = ({color='#fff',height=57,width=57}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M31.6673 3.94531H8.33398C5.50065 3.94531 3.33398 6.11198 3.33398 8.94531V32.2786C3.33398 35.112 5.50065 37.2786 8.33398 37.2786H31.6673C34.5007 37.2786 36.6673 35.112 36.6673 32.2786V8.94531C36.6673 6.11198 34.5007 3.94531 31.6673 3.94531ZM6.66732 32.2786V8.94531C6.66732 7.94531 7.33398 7.27865 8.33398 7.27865H31.6673C32.6673 7.27865 33.334 7.94531 33.334 8.94531V21.612L31.0007 19.2786C29.0007 17.2786 25.834 17.2786 24.0007 19.2786L9.33398 33.9453H8.33398C7.33398 33.9453 6.66732 33.2786 6.66732 32.2786ZM31.6673 33.9453H14.0007L26.334 21.612C27.0007 20.9453 28.0007 20.9453 28.6673 21.612L33.334 26.2786V32.2786C33.334 33.2786 32.6673 33.9453 31.6673 33.9453Z"
                fill={color}/>
            <path
                d="M15 20.6113C17.8333 20.6113 20 18.4447 20 15.6113C20 12.778 17.8333 10.6113 15 10.6113C12.1667 10.6113 10 12.778 10 15.6113C10 18.4447 12.1667 20.6113 15 20.6113ZM15 13.9447C16 13.9447 16.6667 14.6113 16.6667 15.6113C16.6667 16.6113 16 17.278 15 17.278C14 17.278 13.3333 16.6113 13.3333 15.6113C13.3333 14.6113 14 13.9447 15 13.9447Z"
                fill={color}/>
        </svg>

    );
}

export default AInteraction;