import { Flex, Text } from "@chakra-ui/react";
import React, { ReactElement } from "react";

interface SubtitleSeccionProps {
  text: string;
  icon?: ReactElement;
}

const SubtitleSeccion: React.FC<SubtitleSeccionProps> = ({ text, icon }) => {
    return (
        <Flex alignItems="center">   
            {icon && <Flex mr=".5rem">{icon}</Flex>}
            <Text fontFamily="Sansation-Bold"  fontSize="1.1875rem" fontWeight="semibold" color="white.100" textTransform={"capitalize"}>
                {text}
            </Text>
        </Flex>
    );
};

export default SubtitleSeccion;
