import React from 'react';
import {Box} from "@chakra-ui/react";

interface Props {
    children: React.ReactNode
}

export const MainContainer = ({children}: Props) => {

    const isPricing = location.pathname === "/subscription"; 

    return (
        <Box as="main"
            minHeight="100vh"
            display="flex"
            bg="grey.50"
            paddingX={{ base: "0", md: "0" }}
            paddingTop="39px"
            paddingBottom="0"
            alignItems={isPricing ? "flex-start" : "center"}
            justifyContent="center"
        >
            {children}
        </Box>
    )
}