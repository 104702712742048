import { Text,Flex, Select } from "@chakra-ui/react";
import React from "react";
import { SelectInputProps } from './interfaces';

const SelectInput: React.FC<SelectInputProps> = ({ placeholder, options, onChange, label, disabled = false }) => {

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        // Aquí pasamos el valor del select a la función onChange que viene del Controller
        onChange(e.target.value);
    };

    return (
        <Flex mb="15px" width="100%" alignItems="flex-start" flexDir="column">
            {label && (
                <Text mb="7px" fontSize="16px" color="white.50" fontWeight="normal">
                    {label}
                </Text>
            )}
            <Select 
                bg="white.50"
                color="black.300" 
                placeholder={placeholder}
                disabled={disabled}
                onChange={handleChange}
            >
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.name}
                    </option>
                ))}
            </Select>
        </Flex>
    );
};

export default SelectInput;
