import { Box, Flex, Stack, Text} from "@chakra-ui/react";
import MainTitle from "../../../../ui/Components/MainTitle";
import Subtitle from "../../../../ui/Components/SubtitleSeccion"; 
import TextAreaInput from "../../../../ui/Components/TextAreaInput"; 
import ActionButton from "../../../../ui/Components/Button";
import { memo, useState } from "react";
import { useForm, Controller, useWatch } from "react-hook-form";
import GenerativeAILogo from '../../../../icons/brain/generativeai';
import ProvidersCheckbox from "../../../../ui/Components/ProvidersCheckBox";
import SentimentResultsTable from "../../../../ui/Components/SentimentalResultTable";
import {providersListValue} from './mocks';
import { sentimentalAnalysisApi } from "../../../../api/brain/sentimentalAnalysisApi";
import { FormValues, resultsSentimental } from './interfaces'
import useToastError from "../../../../hooks/useToastError";
import SelectInput from "../../../../ui/Components/SelectInput";
import { adjustDataModelsByLanguage } from "./utils";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetTokenOrganization } from "../../../../hooks/useGetTokenOrganization";


const SentimentAnalysis = memo(() => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { handleSubmit, control, formState: { isSubmitting } } = useForm<FormValues>();
    const [chatEnable, setChatEnable] = useState(false);
    const [ showResult, setShowResults ] = useState(false)
    const [results, setResults] = useState<resultsSentimental[]>([{name: "", text: "", time: 0}]);
    const showToastError = useToastError();

    const selectedModel = useWatch({ control, name: "model", defaultValue: [] });
    const selectedLanguage = useWatch({ control, name: "language", defaultValue: "" });
    const enteredText = useWatch({ control, name: "text", defaultValue: "" });
   
    const isFormValid = !!selectedModel.length && selectedLanguage && enteredText.trim();
    const { fetchUpdatedToken } = useGetTokenOrganization();

    const onSubmit = async (data: FormValues) => {
        const result = adjustDataModelsByLanguage(data)
        const token = await fetchUpdatedToken()
        if (typeof token !== 'string' || !token) {
            throw new Error(t("Failed to obtain access token."));
        }
        try {
            const apiResults = await sentimentalAnalysisApi(result, token);
            setResults(apiResults);
            setShowResults(true);
        } catch (error) {
            setShowResults(false)
            showToastError(t('There was an error in the request.'))
        }
    };

    const backNavigate = () => {
        if (chatEnable){
            setChatEnable(false)
        } else {
            navigate('/')
        }
    }
    
    return (
        <Flex flexDir="column">
            <Text
                fontFamily="Lato-regular"
                fontSize="18px" 
                as="a" 
                color="white.50" 
                onClick={backNavigate}
                position="relative"
                right= "401px"
                top="79px" 
            >
                {t("< back")}
            </Text>
            <Box
                maxWidth="670px"
                height="100vh"
                w="670px"
                mx="auto"
                py="100px"
                borderRadius="md"
                color="white"
                px="10px"
            >
           
                <Stack alignItems="flex-start">
                    <Flex mb="46.5px" alignItems="center">
                        <Flex mr="52px">   
                            <MainTitle text={t("Brain")} />
                        </Flex>
                        <Flex alignItems="center"> 
                            <Subtitle text={t("Sentiment Analysis")} icon={<GenerativeAILogo width={24} height={24} color="#FFF" />} />
                        </Flex>
                    </Flex>
                    <Text fontWeight="bold" fontSize="20px" mb="33px" textAlign="initial">
                        {t("Identify the sentiment of the speakers on a conversation or message.")}
                    </Text>
                    <form 
                        onSubmit={handleSubmit(onSubmit)}
                        style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
                    >
                        <Controller
                            name="model"
                            control={control}
                            defaultValue={[]} 
                            render={({ field }) => (
                                <ProvidersCheckbox
                                    providers={providersListValue}
                                    value={field.value}
                                    onChange={(selectedValues) => field.onChange(selectedValues)}
                                />
                            )}
                        />
                        <Controller
                            name="language"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <SelectInput
                                    placeholder={t("Choose a language")}
                                    options={[{ name: t('Spanish'), value: "español"}, {name: t('English'), value: "inglés"}]}
                                    onChange={field.onChange}
                                    label={t("Select provider")}
                                />
                            )}
                        />
                        <Controller
                            name="text"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <TextAreaInput
                                    placeholder={t("You are a helpful assistant")}
                                    value={field.value}
                                    onChange={field.onChange}
                                    label={t("Text to analyze")}
                                />
                            )}
                        />

                        <ActionButton
                            text={t("Analyze")}
                            onClick={handleSubmit(onSubmit)}
                            isLoading={isSubmitting}  
                            isDisabled={!isFormValid}
                        />
                    </form>
                    {showResult &&  <SentimentResultsTable results={results} />}
                   
                   
                </Stack>
           
            
            </Box>
        </Flex>
    );
});

export default SentimentAnalysis;
