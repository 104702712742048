import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import MainTitle from "../../../../ui/Components/MainTitle";
import Subtitle from "../../../../ui/Components/SubtitleSeccion";
import { memo, useState } from "react";
import { useForm, Controller, useWatch  } from "react-hook-form";
import { AIGenerate } from "../../../../icons";
import useToastError from '../../../../hooks/useToastError';
import ChatBox from '../../../../ui/Components/ChatBox';
import { providersListGenerative } from './mocks';
import './stylesGenerativeAi.css';
import { FormValues } from './interfaces';
import { useTranslation } from "react-i18next";
import {Select, Input, Button } from "@canaia/ui-kit";

const GenerativeAI = memo(() => {
    //const navigate = useNavigate();
    const { t } = useTranslation();
    const { handleSubmit, control, setValue, formState: { isSubmitting } } = useForm<FormValues>();
    const showToastError = useToastError();
    const [chatEnable, setChatEnable] = useState(false);
    const [sendUserMessage, setSendUserMessage] = useState<FormValues>({
        model: "",
        system_prompt: "",
    });

    const selectedProviders = useWatch({
        control,
        name: "model",
        defaultValue: "",
    });

    const systemPromptValue = useWatch({
        control,
        name: "system_prompt",
        defaultValue: "",
    });

    const SelectProps = {
        label: 'Seleccionar proveedor',
        fontFamilyLabel:'Sansation-Bold',
        fontSizeLabel: '1rem',
        options: providersListGenerative,
        placeholder: 'Choose an option',
        color: 'black.100',
        variant: 'filled',
        infoText:'Seleccione el provedor',
        bgColorTooltip: 'rgba(254, 226, 220, 1)',
        borderTooltip:'1px solid rgba(249, 57, 22, 1)',
        borderRadiusTooltip: '.25rem',
        textAlignTooltip: 'justify',
        colorTextTooltip: '#222222',
        hasArrowTooltip: false,
        disableSelect: false
    }

    const TextAreaProps = {
        colorLabelText:'white.100',
        fontFamilyText:'Sansation-Bold',
        fontSizeText: '1rem',
        isRequired: true,
        info: true,
        infoText: 'Directrices para la IA',
        label : 'Directrices para la IA',
        placeholder: 'Eres un asistente muy útil...',
        textArea: true,
        isReadOnly: false,
        isDisabled: false,
        minHeight: '92px'
    }

    const onSubmit = async  (data: FormValues) => {
        const sendData = {
            model: data.model,
            system_prompt: data.system_prompt,
        };
        if(sendData){
            setChatEnable(true);
            setSendUserMessage(sendData)
            // reset select
            setValue("model","");
        }
    };

    // Enable button only if both fields have content
    const isButtonEnabled = selectedProviders && selectedProviders.length > 0 && systemPromptValue.trim().length > 0;

    const ButtonProps = {
        text: 'Probar',
        type: 'subtle',
        color: 'white',
        bgColor: isButtonEnabled ? 'play.600' : '#c1c1c1',
        onClick: handleSubmit(onSubmit),
        loading: isSubmitting,
        disabled: !isButtonEnabled,
    }
    
    return (
        <Flex flexDir="column">
            <Box
                maxWidth="770px"
                height="100vh"
                w="770px"
                mx="auto"
                py="50px"
                borderRadius="md"
                color="white"
                px="10px"
            >
           
                <Stack alignItems="flex-start" height="90%">
                    <Flex alignItems="center">
                        <Flex mr="1.5rem">
                            <MainTitle text={t("Brain")} />
                        </Flex>
                        <Flex alignItems="center"> 
                            <Subtitle text={t("Generative AI")} icon={<AIGenerate width={24} height={24} color="#fff" />} />
                        </Flex>
                    </Flex>
                    <Text fontWeight="bold" fontSize="20px" mb="33px" textAlign="initial">
                        {t("Try the different Generative AI that your product can work with.")}
                    </Text>
                    {!chatEnable ? (
                        <>
                            <form 
                                onSubmit={handleSubmit(onSubmit)}
                                style={{ width: '100%', display: 'flex', flexDirection: 'column', border:'1px solid #c1c1c1', borderRadius: '1rem', padding: '1.1875rem 1.1875rem ' }}
                            >
                                <Controller
                                    name="model"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Select {...SelectProps} onChange={field.onChange}/>
                                    )}
                                />

                                <Controller
                                    name="system_prompt"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Box margin='1.5rem 0 0 0'>
                                            <Input {...TextAreaProps} onChange={field.onChange} value={field.value}/>
                                        </Box>

                                    )}
                                />

                                <Flex maxWidth='6.375rem' fontSize='1.125rem' margin='1.56rem auto 2.4375rem'>
                                    <Button {...ButtonProps}/>
                                </Flex>

                            </form>
                 
                        </>

                    ) : (
                        <ChatBox sendUserMessage={sendUserMessage} showToastError={showToastError} />
                    )}
                    
                </Stack>

            </Box>
        </Flex>
    );
});

export default GenerativeAI;
