import React from 'react';

interface Props {
    color?: string;
    height?: number;
    width?: number;
}

const Ear : React.FC<Props> = ({color='#183b4d', height=24, width=24}) => {
    return (
        <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24" width={width} height={height}  fill="none">
            <path
                d="M9,23c-2.5,0-4.5-2-4.5-4.5s.4-1,1-1,1,.4,1,1c0,1.4,1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5c0-2.3,1.5-3.5,2.9-4.7,1.6-1.3,3.1-2.5,3.1-5.3s-2.5-5.5-5.5-5.5-5.5,2.5-5.5,5.5-.4,1-1,1-1-.4-1-1C4.5,4.4,7.9,1,12,1s7.5,3.4,7.5,7.5-2.1,5.5-3.8,6.9c-1.4,1.1-2.2,1.8-2.2,3.1,0,2.5-2,4.5-4.5,4.5Z"
                fill={color}/>
            <path
                d="M9.5,14.5c-.6,0-1-.4-1-1s.4-1,1-1,1-.4,1-1-.4-1-1-1-1-.4-1-1v-1c0-1.9,1.6-3.5,3.5-3.5s3.5,1.6,3.5,3.5-.4,1-1,1-1-.4-1-1c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5,1.5v.2c1.2.4,2,1.5,2,2.8s-1.3,3-3,3Z"
                fill={color}/>
        </svg>
    );
}

export default Ear;