import  React from 'react';

interface Props {
    color?: string;
    height?: number;
    width?: number;
}

const VoiceClonning: React.FC<Props> = ({color='#fff',height=57,width=57}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5098_8611)">
                <path
                    d="M36.6667 15.6108C35.6667 15.6108 35 16.2775 35 17.2775V20.6108C35 23.2775 34 25.7775 32 27.6108C30.1667 29.4442 27.6667 30.6108 25 30.6108C22.3333 30.6108 24.1667 30.6108 23.8333 30.6108C22.6667 31.6108 21.3333 32.4442 20 32.9442C21 33.4442 22.1667 33.7775 23.3333 33.7775V37.2775H21.6667C22.6667 37.2775 23.3333 37.9442 23.3333 38.9442C23.3333 39.9442 22.6667 40.6108 21.6667 40.6108H31.6667C32.6667 40.6108 33.3333 39.9442 33.3333 38.9442C33.3333 37.9442 32.6667 37.2775 31.6667 37.2775H26.6667V33.7775C29.5 33.4442 32.3333 32.1108 34.3333 29.9442C36.8333 27.4442 38.1667 24.1108 38.1667 20.6108V17.2775C38.1667 16.2775 37.5 15.6108 36.5 15.6108H36.6667Z"
                    fill={color}/>
                <path
                    d="M20 24.9442C19 23.7775 18.3333 22.2775 18.3333 20.6108C18.3333 21.4442 18 22.2775 17.3333 22.9442C16.8333 23.4442 16.1667 23.7775 15.5 23.9442C15.8333 24.9442 16.5 25.9442 17.1667 26.9442C18.1667 26.6108 19 26.1108 19.6667 25.4442C19.6667 25.4442 19.8333 25.2775 20 25.1108V24.9442Z"
                    fill={color}/>
                <path
                    d="M11.6654 17.2775V7.27751C11.6654 6.44417 11.9987 5.61084 12.6654 4.94417C13.332 4.27751 14.1654 3.94417 14.9987 3.94417C15.832 3.94417 16.6654 4.27751 17.332 4.94417C17.9987 5.61084 18.332 6.44417 18.332 7.27751C18.332 5.61084 18.9987 4.11084 19.9987 2.94417C19.9987 2.94417 19.832 2.77751 19.6654 2.61084C18.4987 1.44417 16.6654 0.61084 14.9987 0.61084C13.332 0.61084 11.4987 1.27751 10.332 2.61084C9.16536 3.94417 8.33203 5.61084 8.33203 7.27751V20.6108C8.33203 22.4442 8.9987 24.1108 10.332 25.2775C11.1654 26.1108 12.1654 26.6108 13.332 26.9442C12.332 24.9442 11.6654 22.7775 11.6654 20.6108V17.2775Z"
                    fill={color}/>
                <path
                    d="M18.332 20.611C18.332 22.2777 18.9987 23.7777 19.9987 24.9443C20.9987 23.7777 21.6654 22.2777 21.6654 20.611V7.27767C21.6654 5.611 20.9987 4.111 19.9987 2.94434C18.9987 4.111 18.332 5.611 18.332 7.27767V20.611Z"
                    fill={color}/>
                <path
                    d="M17.166 26.7777C16.4993 25.9443 15.8327 24.9443 15.4993 23.7777C15.3327 23.7777 15.166 23.7777 14.9993 23.7777C14.166 23.7777 13.3327 23.4443 12.666 22.7777C11.9993 22.111 11.666 21.2777 11.666 20.4443C11.666 22.7777 12.166 24.9443 13.3327 26.7777C13.8327 26.7777 14.4993 26.9443 14.9993 26.9443C15.4993 26.9443 16.4993 26.9443 17.166 26.611V26.7777Z"
                    fill={color}/>
                <path
                    d="M16.666 38.7777C16.666 37.7777 17.3327 37.111 18.3327 37.111H16.666V33.611C17.8327 33.611 18.9993 33.111 19.9993 32.7777C18.666 32.2777 17.3327 31.4443 16.166 30.4443C15.8327 30.4443 15.3327 30.4443 14.9993 30.4443C12.3327 30.4443 9.83268 29.4443 7.99935 27.4443C6.16602 25.611 4.99935 23.111 4.99935 20.4443V17.111C4.99935 16.111 4.33268 15.4443 3.33268 15.4443C2.33268 15.4443 1.66602 16.111 1.66602 17.111V20.4443C1.66602 23.9443 2.99935 27.2777 5.49935 29.7777C7.66602 31.9443 10.3327 33.111 13.166 33.611V37.111H8.16602C7.16602 37.111 6.49935 37.7777 6.49935 38.7777C6.49935 39.7777 7.16602 40.4443 8.16602 40.4443H18.166C17.166 40.4443 16.4993 39.7777 16.4993 38.7777H16.666Z"
                    fill={color}/>
                <path
                    d="M24.9993 3.94417C25.8327 3.94417 26.666 4.27751 27.3327 4.94417C27.9993 5.61084 28.3327 6.44417 28.3327 7.27751V20.6108C28.3327 21.4442 27.9993 22.2775 27.3327 22.9442C26.666 23.6108 25.8327 23.9442 24.9993 23.9442C24.166 23.9442 24.666 23.9442 24.4993 23.9442C24.166 24.9442 23.4993 25.9442 22.8327 26.9442C22.666 27.2775 22.3327 27.4442 22.166 27.7775C21.4993 28.4442 20.8327 28.9442 20.166 29.2775C21.3327 29.9442 22.666 30.4442 23.9993 30.6108C24.166 30.4442 24.3327 30.2775 24.666 30.1108C25.4993 29.2775 26.3327 28.2775 26.8327 27.1108C27.9993 26.7775 28.9993 26.2775 29.8327 25.4442C30.9993 24.2775 31.8327 22.4442 31.8327 20.7775V7.27751C31.8327 5.44417 31.166 3.77751 29.8327 2.61084C28.666 1.44417 26.8327 0.61084 25.166 0.61084C23.4993 0.61084 23.666 0.61084 22.9993 0.944173C23.666 1.77751 24.3327 2.77751 24.666 3.94417"
                    fill={color}/>
                <path
                    d="M23.8327 30.4442C22.4994 30.4442 21.166 29.7775 19.9993 29.1108C18.8327 29.7775 17.4993 30.2775 16.166 30.4442C17.3327 31.4442 18.666 32.2775 19.9993 32.7775C21.3327 32.2775 22.666 31.4442 23.8327 30.4442Z"
                    fill={color}/>
                <path
                    d="M23.3327 38.7775C23.3327 37.7775 22.666 37.1108 21.666 37.1108H18.3327C17.3327 37.1108 16.666 37.7775 16.666 38.7775C16.666 39.7775 17.3327 40.4442 18.3327 40.4442H21.666C22.666 40.4442 23.3327 39.7775 23.3327 38.7775Z"
                    fill={color}/>
                <path
                    d="M24.9993 3.94434C24.166 3.94434 23.166 4.111 22.4993 4.77767C21.8327 5.44434 21.666 6.27767 21.666 7.27767V20.611C21.666 21.4443 21.9993 22.2777 22.666 22.9443C23.3327 23.611 24.166 23.9443 24.9993 23.9443C25.8327 23.9443 26.666 23.611 27.3327 22.9443C27.9993 22.2777 28.3327 21.4443 28.3327 20.611V7.27767C28.3327 6.44434 27.9993 5.611 27.3327 4.94434C26.666 4.27767 25.8327 3.94434 24.9993 3.94434Z"
                    fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_5098_8611">
                    <rect width="40" height="40" fill="white" transform="translate(0 0.61084)"/>
                </clipPath>
            </defs>
        </svg>


    );
};

export default VoiceClonning;