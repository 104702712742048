import React, { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { Box } from "@chakra-ui/react";
import { useLogto } from "@logto/react";
import {authStore} from "../../../store/authStore.tsx";
import { useTranslation } from "react-i18next";

import { Header } from '@canaia/ui-kit';

const Navbar: React.FC = () => {
    const {signOut} = useLogto();
    const {userName} = authStore();
    const { t } = useTranslation();

    const LogOut = () => {
        signOut(window.location.origin + "/").then()
    }

    const authContext = useContext(AuthContext);
    if (!authContext) {
        return null;
    }

    const HeaderProps  = {
        platform: "play",
        color: "play.500",
        settingsName: t("Settings"),
        showMenuItems: ['hub','play','assist','elevate'],
        onSettingsClick: () => window.location.href = 'https://staging.hub.canaia.ai/settings',
        onLogOutClick: () => LogOut(),
        userName: userName ? userName : "User Name",
    };

    return (
        <Box 
            bg="white" 
            boxShadow="sm" 
            pt="12px" 
            pb="12px"
            paddingX={{ base: "10px", md: "80px" }}
        >
            <Header {...HeaderProps}/>
        </Box>
    );
};

export default Navbar;
