import React from 'react';

interface Props {
    color?: string;
    height?: number;
    width?: number;
}

const AIGeneration: React.FC<Props> = ({color='#fff',height=57,width=57}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M29.6667 23.444L33 20.1107C33.6667 19.444 33.6667 18.444 33 17.7773L19.6667 4.44401C19 3.77734 18 3.77734 17.3333 4.44401L14.3333 7.44401L9.66667 2.77734C9 2.11068 8 2.11068 7.33333 2.77734C6.66667 3.44401 6.66667 4.44401 7.33333 5.11068L12 9.77734L3 18.7773C1.16667 20.7773 1.16667 23.7773 3 25.7773L11.6667 34.444C12.6667 35.444 13.8333 35.944 15.1667 35.944C16.5 35.944 17.6667 35.444 18.6667 34.444L29.6667 23.444ZM15.6667 13.444C16 13.7773 16.5 13.944 16.8333 13.944C17.1667 13.944 17.6667 13.944 18 13.444C18.6667 12.7773 18.6667 11.7773 18 11.1107L16.6667 9.77734L18.5 7.94401L29.5 18.944L27.8333 20.6107H5.83333L14.3333 12.1107L15.6667 13.444ZM16.3333 32.1107C15.6667 32.7773 14.6667 32.7773 14 32.1107L5.83333 23.944H24.5L16.3333 32.1107Z"
                fill={color}/>
            <path
                d="M36.5 29.444C35.8333 28.6107 35.3333 27.944 35.1667 27.1107C35.1667 26.2773 34.3333 25.7773 33.5 25.7773C32.6667 25.7773 32 26.2773 31.8333 27.1107C31.8333 27.944 31.1667 28.6107 30.5 29.444C29.6667 30.6107 28.5 31.944 28.5 33.944C28.5 35.944 30.6667 38.944 33.5 38.944C36.3333 38.944 38.5 36.7773 38.5 33.944C38.5 31.1107 37.3333 30.444 36.5 29.444ZM33.5 35.6107C32.5 35.6107 31.8333 34.944 31.8333 33.944C31.8333 32.944 32.3333 32.444 33.1667 31.444C33.1667 31.444 33.3333 31.1107 33.5 30.944C33.5 30.944 33.8333 31.2773 33.8333 31.444C34.6667 32.444 35.1667 33.1107 35.1667 33.944C35.1667 34.7773 34.5 35.6107 33.5 35.6107Z"
                fill={color}/>
        </svg>

    );
}

export default AIGeneration;