import React from 'react';

interface Props {
    color?: string;
    height?: number;
    width?: number;
}

const Eye: React.FC<Props> = ({ color='#183b4d', height=24,width = 24}) => {
    return (
        <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24" width={width} height={height} fill="none">
            <path
                d="M12,20c-1.5,0-3-.3-4.5-.9-2.9-1.2-5.2-3.5-6.4-6.4,0,0,0,0,0,0-.2-.4-.2-.9,0-1.4C3.6,5.3,10.5,2.4,16.5,4.9c2.9,1.2,5.2,3.5,6.4,6.4.2.5.2,1,0,1.4-1.9,4.6-6.3,7.3-10.9,7.3ZM3,12c1,2.4,2.9,4.3,5.3,5.3,5,2.1,10.7-.3,12.7-5.3-1-2.3-2.9-4.2-5.3-5.2-5-2-10.7.3-12.7,5.3Z"
                fill={color}/>
            <path
                d="M12,16c-2.2,0-4-1.8-4-4s1.8-4,4-4,4,1.8,4,4-1.8,4-4,4ZM12,10c-1.1,0-2,.9-2,2s.9,2,2,2,2-.9,2-2-.9-2-2-2Z"
                fill={color}/>
        </svg>
    );
}

export default Eye;