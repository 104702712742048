import React from 'react';

interface Props {
    color?: string;
    height?: number;
    width?: number;}

const VoiceUnderstandingIcon: React.FC<Props> = ({color='#fff',height=57,width=57}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M36.6676 14.6103C35.3343 10.4436 32.3343 6.94362 28.3343 4.94362C20.3343 0.610291 10.1676 3.94362 6.00093 12.1103C3.6676 16.6103 3.50093 22.1103 5.6676 26.6103L2.50093 35.777C2.33426 36.4436 2.50093 37.1103 2.83426 37.4436C3.1676 37.777 3.50093 37.9436 4.00093 37.9436C4.50093 37.9436 4.33426 37.9436 4.50093 37.9436L13.6676 34.777C17.5009 36.4436 21.6676 36.777 25.6676 35.4436C29.8343 34.1103 33.3343 31.1103 35.3343 27.1103C37.3343 23.1103 37.6676 18.6103 36.3343 14.4436L36.6676 14.6103ZM32.6676 25.777C31.0009 28.9436 28.3343 31.277 24.8343 32.4436C21.5009 33.6103 17.8343 33.277 14.6676 31.6103C14.3343 31.4436 13.8343 31.4436 13.3343 31.6103L6.6676 33.777L9.00093 27.1103C9.00093 26.6103 9.00093 26.277 9.00093 25.777C7.00093 21.9436 7.00093 17.4436 9.00093 13.6103C11.3343 8.94362 16.0009 6.44362 20.8343 6.44362C25.6676 6.44362 25.0009 6.94362 26.8343 7.94362C30.0009 9.61029 32.3343 12.277 33.5009 15.777C34.6676 19.1103 34.3343 22.777 32.6676 25.9436V25.777Z"
                fill={color}/>
            <path
                d="M14.1667 18.1104C13.1667 18.1104 12.5 18.777 12.5 19.777C12.5 20.777 13.3333 21.4437 14.1667 21.4437C15 21.4437 15.8333 20.777 15.8333 19.777C15.8333 18.777 15.1667 18.1104 14.1667 18.1104Z"
                fill={color}/>
            <path
                d="M20.8327 18.1104C19.8327 18.1104 19.166 18.777 19.166 19.777C19.166 20.777 19.9993 21.4437 20.8327 21.4437C21.666 21.4437 22.4993 20.777 22.4993 19.777C22.4993 18.777 21.8327 18.1104 20.8327 18.1104Z"
                fill={color}/>
            <path
                d="M27.5007 18.1104C26.5007 18.1104 25.834 18.777 25.834 19.777C25.834 20.777 26.6673 21.4437 27.5007 21.4437C28.334 21.4437 29.1673 20.777 29.1673 19.777C29.1673 18.777 28.5007 18.1104 27.5007 18.1104Z"
                fill={color}/>
        </svg>
    );
};
export default VoiceUnderstandingIcon;