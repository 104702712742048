import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import './PageHome.css';
import HomeOption from "../../atoms/HomeOption/HomeOption.tsx";
import { Box, Image } from "@chakra-ui/react";
import GirlImage from '../../../assets/imgs/bckg-girl-home.png';
import ModalOptionsHome from "../ModalOptionsHome/ModalOptionsHome.tsx";
import { AIGenerate, SentimentalAnalysis, VoiceU, AIVoices, AInteraction, AIMGeneration, AICloning, Brain, Ear, Eye, Mouth } from "../../../icons";
import { useTranslation } from "react-i18next";

//Defines the type of the reference (ref)
export interface ModalOption {
    icon: JSX.Element;
    title: string;
    description: string;
    link: string;
}

// Interface to define the reference (ref) to be used to open the modal
export interface PageHomeRef {
    openModalWithOptions: (optionId: string) => void;
}

interface Props {}

// We use `forwardRef` to be able to pass a reference (ref) from the parent component to `PageHome`.
// This allows us to access functions inside `PageHome` from the `Layout` component.
const PageHome = forwardRef<PageHomeRef, Props>((_,ref) => {
    const { t } = useTranslation();
    const descriptionBrain = t('Try the different Generative AI that your product can work with.');
    const descriptionEar = t('See how our diverse audio transcriber works.');
    const descriptionEye = t('Describe something and let the Generative AI generate the image.');
    const descriptionMouth = t('Make your conversational AI speak like whoever you want.');

    const [optionSelected, setOptionSelected] = useState<string>('');
    const [arrayOptions, setArrayOptions] = useState<ModalOption[]>([]);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [elementsHidden, setElementsHidden] = useState<boolean>(false);

    // Ref for modal
    const modalRef = useRef<HTMLDivElement | null>(null);

    // Array of options
    const brainOptions: ModalOption[] = [
        {
            icon: <AIGenerate  width={34} height={34} color={'#fa6448'}/>,
            title: t('Generative AI'),
            description: t('Test different AI models by interacting with them directly.'),
            link: '/play/brain/generative-ai',
        },
        {
            icon: <SentimentalAnalysis width={34} height={34} color={'#fa6448'}/>,
            title: t('Sentiment Analysis'),
            description: t('Identify the emotional tone of the conversation.'),
            link: '/play/brain/sentiment-analysis',
        }
    ];
    const earOptions: ModalOption[] = [
        {
            icon: <VoiceU width={34} height={34} color={'#fa6448'} />,
            title: t('Voice Understanding'),
            description: t('Compare various voice-to-text transcription options to find the best fit.'),
            link: '/play/ear/voice-understanding',
        }
    ];
    const eyeOptions: ModalOption[] = [
        {
            icon: <AInteraction width={34} height={34} color={'#fa6448'}/>,
            title: t('Image interaction'),
            description: t('Analyze visual content using AI-powered language models.'),
            link: '/play/eyes/image-interaction',
        },
        {
            icon: <AIMGeneration width={34} height={34} color={'#fa6448'}/>,
            title: t('Image generation'),
            description: t('Transform text prompts into high-quality images using AI-powered language models.'),
            link: '/play/eyes/image-generation',
        }
    ];
    const mouthOptions: ModalOption[] = [
        {
            icon: <AIVoices width={34} height={34} color={'#fa6448'}/>,
            title: t('Voces IA'),
            description: t('Explore and compare different AI-generated voices for your needs.'),
            link: '/play/mouth/ai-voices'
        },
        {
            icon: <AICloning width={34} height={34} color={'#fa6448'}/>,
            title: t('Voice Clonning'),
            description: t('Clone a voice and test it by having it speak your inputted text.'),
            link: '/play/mouth/voice-cloning'
        }
    ];

    // Title and options assignment depending on the selected button
    const optionsToModal: { [key: string]: { title: string; options: ModalOption[] } } = {
        "1": { title: 'Brain', options: brainOptions },
        "2": { title: 'Ear', options: earOptions },
        "3": { title: 'Eye', options: eyeOptions },
        "4": { title: 'Mouth', options: mouthOptions },
    };

    // We expose the `openModalWithOptions` function so that it can be called from outside PageHome
    useImperativeHandle(ref, () => ({
        openModalWithOptions: (optionId: string) => {
            const selectedOption = optionsToModal[optionId];
            if (selectedOption) {
                setOptionSelected(selectedOption.title); // title of the option selected
                setArrayOptions(selectedOption.options); // options
                setOpenModal(true); // Open modal
                setElementsHidden(true); // Hide elements behind the modal
            }
        }
    }));

    // Function to send functions and open modal
    const handleModal = (event: React.MouseEvent<HTMLElement>) => {
        const optionId: string | null = event.currentTarget.getAttribute("data-id");
        if (optionId != null) {
            const selectedOption = optionsToModal[optionId];
            if (selectedOption) {
                setOptionSelected(selectedOption.title);
                setArrayOptions(selectedOption.options);
            }
        }

        setOpenModal(true);
        setElementsHidden(true);
    };

    // Handles event to close the modal
    const closeModal = () => {
        setOpenModal(false);
        setElementsHidden(false);
    };

    // useEffect to control clicks outside the modal
    useEffect(() => {
        const clickOutside = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                closeModal();
            }
        };

        if (openModal) {
            document.addEventListener('click', clickOutside);
        }

        return () => {
            document.removeEventListener('click', clickOutside);
        };
    }, [openModal]);

    return (
        <div className="wrapper-home-page">
            <Box className='wrapper-girl-img'>
                {!elementsHidden &&
                    <Box className="home-options-wrapper left">
                        <Box data-id="1" className="inner-option" onClick={handleModal} cursor={'pointer'}>
                            <HomeOption icon={<Brain color='#F93916' height={36} width={36} />} title={t('Brain')} description={descriptionBrain} />
                        </Box>
                        <Box data-id="2" onClick={handleModal} cursor={'pointer'} className="inner-option">
                            <HomeOption icon={<Ear color='#F93916' height={36} width={36} />} title={t('Ear')} description={descriptionEar} />
                        </Box>
                    </Box>
                }
                <Image src={GirlImage} />
                {!elementsHidden &&
                    <Box className="home-options-wrapper right">
                        <Box data-id="3" onClick={handleModal} cursor={'pointer'} className="inner-option">
                            <HomeOption icon={<Eye color='#F93916' height={36} width={36} />} title={t('Eye')} description={descriptionEye} />
                        </Box>
                        <Box data-id="4" onClick={handleModal} cursor={'pointer'} className="inner-option">
                            <HomeOption icon={<Mouth color='#F93916' height={36} width={36} />} title={t('Mouth')} description={descriptionMouth} />
                        </Box>
                    </Box>
                }
                <ModalOptionsHome isOpen={openModal} onClose={closeModal} optionTitle={optionSelected} options={arrayOptions} />
            </Box>
        </div>
    );
});

export default PageHome;
