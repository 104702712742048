import React from 'react';
import './HomeOption.css'
import {Box, Flex, Text} from "@chakra-ui/react";
import { CardCustom } from '@canaia/ui-kit';

interface Props {
    icon: React.ReactNode;
    title: string;
    description: string;
}

const HomeOption : React.FC<Props> = ({ icon,title, description, }) => {
    return (
        <>
            <Box className="home-option">
                <CardCustom backgroundColor='#fff' maxWidth='214px' padding={'1.6875rem 1.125rem'} borderRadius='1rem'>
                    <Flex direction='column' alignItems='center'>
                        {icon}
                        <Text color='#F93916' fontSize='2rem'  fontFamily='Sansation-Bold' margin='.75rem 0 1.5rem 0'>{title}</Text>
                        <Text color='#222' fontSize='1rem' fontFamily='Sansation-Regular'>{description}</Text>
                    </Flex>
                </CardCustom>
            </Box>
        </>

    );
}

export default HomeOption;
