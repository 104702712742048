import React from 'react';

interface Props {
    color?: string;
    height?: number;
    width?: number;
}

const Mouth: React.FC<Props> = ({color='#183b4d', height=24,width = 24}) =>{
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.49961 1.8C5.79961 1.8 4.19961 2.5 2.99961 3.7C1.79961 4.9 1.09961 6.5 1.09961 8.2C1.09961 10.3 1.49961 11.2 1.79961 11.9C1.89961 12.2 2.09961 12.5 2.19961 13C2.39961 13.7 2.39961 14.5 2.19961 15.3L1.19961 19.7C1.09961 20.2 1.39961 20.8 1.89961 20.9H2.09961C2.59961 20.9 2.99961 20.6 3.09961 20.1L4.09961 15.7C4.29961 14.6 4.29961 13.5 4.09961 12.5C3.99961 11.9 3.79961 11.5 3.59961 11.1C3.29961 10.4 3.09961 9.8 3.09961 8.1C3.09961 6.9 3.59961 5.8 4.39961 5C5.19961 4.2 6.29961 3.7 7.49961 3.7C8.69961 3.7 9.79961 4.2 10.5996 5C11.3996 5.8 11.8996 7 11.8996 8.1V13.7C11.8996 14.3 11.3996 14.8 10.6996 14.9L8.89961 14.7C8.59961 14.7 8.29961 14.7 8.09961 15C7.89961 15.3 7.79961 15.5 7.79961 15.7V19.8C7.79961 20.4 8.19961 20.8 8.79961 20.8C9.39961 20.8 9.79961 20.4 9.79961 19.8V16.8H10.5996C10.5996 16.8 10.6996 16.8 10.7996 16.8C12.3996 16.8 13.7996 15.4 13.8996 13.7V8C13.8996 6.3 13.1996 4.7 11.9996 3.4C10.7996 2.1 9.19961 1.5 7.49961 1.5V1.8Z"
                fill={color}/>
            <path
                d="M20.5008 6.49995C20.1008 6.09995 19.5008 6.09995 19.1008 6.49995C18.7008 6.89995 18.7008 7.49995 19.1008 7.89995C21.6008 10.4 21.6008 14.6 19.1008 17.1C18.7008 17.5 18.7008 18.1 19.1008 18.5C19.5008 18.9 19.6008 18.8 19.8008 18.8C20.0008 18.8 20.3008 18.8 20.5008 18.5C23.8008 15.2 23.8008 9.79995 20.5008 6.49995Z"
                fill={color}/>
            <path
                d="M17.7 9.29996C17.3 8.89996 16.7 8.99996 16.3 9.29996C15.9 9.69996 15.9 10.3 16.3 10.7C17.3 11.7 17.3 13.3 16.3 14.2C15.9 14.6 15.9 15.2 16.3 15.6C16.7 16 16.8 15.9 17 15.9C17.2 15.9 17.5 15.9 17.7 15.6C19.5 13.8 19.5 11 17.7 9.19996V9.29996Z"
                fill={color}/>
        </svg>
    );
}

export default Mouth;