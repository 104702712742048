import { Text } from "@chakra-ui/react";
import React from "react";

interface MainTitleProps {
  text: string;
}

const MainTitle: React.FC<MainTitleProps> = ({ text }) => {
    return (
        <Text fontFamily="Sansation-Bold" fontSize="1.5rem" fontWeight="bold" color="#fcb8Ab" textTransform={"capitalize"}>
            {text}
        </Text>
    );
};

export default MainTitle;
