import  React from 'react';

interface Props {
    color?: string;
    height?: number;
    width?: number;
}

const sentimentalAnalysis : React.FC<Props> = ({color='#fff',height=57,width=57}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.00065 13.9453C6.00065 13.9453 6.66732 13.2786 6.66732 12.2786V8.94531C6.66732 7.94531 7.33398 7.27865 8.33398 7.27865H11.6673C12.6673 7.27865 13.334 6.61198 13.334 5.61198C13.334 4.61198 12.6673 3.94531 11.6673 3.94531H8.33398C5.50065 3.94531 3.33398 6.11198 3.33398 8.94531V12.2786C3.33398 13.2786 4.00065 13.9453 5.00065 13.9453Z"
                fill={color}/>
            <path
                d="M31.666 3.94531H28.3327C27.3327 3.94531 26.666 4.61198 26.666 5.61198C26.666 6.61198 27.3327 7.27865 28.3327 7.27865H31.666C32.666 7.27865 33.3327 7.94531 33.3327 8.94531V12.2786C33.3327 13.2786 33.9993 13.9453 34.9993 13.9453C35.9993 13.9453 36.666 13.2786 36.666 12.2786V8.94531C36.666 6.11198 34.4993 3.94531 31.666 3.94531Z"
                fill={color}/>
            <path
                d="M34.9993 27.2773C33.9993 27.2773 33.3327 27.944 33.3327 28.944V32.2773C33.3327 33.2773 32.666 33.944 31.666 33.944H28.3327C27.3327 33.944 26.666 34.6107 26.666 35.6107C26.666 36.6107 27.3327 37.2773 28.3327 37.2773H31.666C34.4993 37.2773 36.666 35.1107 36.666 32.2773V28.944C36.666 27.944 35.9993 27.2773 34.9993 27.2773Z"
                fill={color}/>
            <path
                d="M11.6673 33.944H8.33398C7.33398 33.944 6.66732 33.2773 6.66732 32.2773V28.944C6.66732 27.944 6.00065 27.2773 5.00065 27.2773C4.00065 27.2773 3.33398 27.944 3.33398 28.944V32.2773C3.33398 35.1107 5.50065 37.2773 8.33398 37.2773H11.6673C12.6673 37.2773 13.334 36.6107 13.334 35.6107C13.334 34.6107 12.6673 33.944 11.6673 33.944Z"
                fill={color}/>
            <path
                d="M27.9997 24.9444C28.4997 24.2777 28.333 23.111 27.6664 22.611C26.9997 22.111 25.833 22.2777 25.333 22.9444C25.333 22.9444 23.1664 25.611 19.9997 25.611C16.833 25.611 14.6664 22.9444 14.6664 22.9444C14.1664 22.2777 12.9997 22.111 12.333 22.611C11.6664 23.111 11.4997 24.2777 11.9997 24.9444C11.9997 24.9444 14.9997 28.9444 19.9997 28.9444C24.9997 28.9444 27.833 25.111 27.9997 24.9444Z"
                fill={color}/>
            <path
                d="M15.0007 17.2786C16.0007 17.2786 16.6673 16.612 16.6673 15.612C16.6673 14.612 16.0007 13.9453 15.0007 13.9453C14.0007 13.9453 13.334 14.612 13.334 15.612C13.334 16.612 14.1673 17.2786 15.0007 17.2786Z"
                fill={color}/>
            <path
                d="M25.0007 17.2786C26.0007 17.2786 26.6673 16.612 26.6673 15.612C26.6673 14.612 26.0007 13.9453 25.0007 13.9453C24.0007 13.9453 23.334 14.612 23.334 15.612C23.334 16.612 24.1673 17.2786 25.0007 17.2786Z"
                fill={color}/>
        </svg>


    );
}
export default sentimentalAnalysis;