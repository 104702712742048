import React from "react";

interface Props {
    color?: string;
    height?: number;
    width?: number;
}

const AIVoices: React.FC<Props> = ({color='#fff',height=57,width=57}) =>{
    return (
        <svg width={width} height={height} viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.00065 13.9443C6.00065 13.9443 6.66732 13.2777 6.66732 12.2777V8.94434C6.66732 7.94434 7.33398 7.27767 8.33398 7.27767H11.6673C12.6673 7.27767 13.334 6.611 13.334 5.611C13.334 4.611 12.6673 3.94434 11.6673 3.94434H8.33398C5.50065 3.94434 3.33398 6.111 3.33398 8.94434V12.2777C3.33398 13.2777 4.00065 13.9443 5.00065 13.9443Z"
                fill={color}/>
            <path
                d="M31.666 3.94434H28.3327C27.3327 3.94434 26.666 4.611 26.666 5.611C26.666 6.611 27.3327 7.27767 28.3327 7.27767H31.666C32.666 7.27767 33.3327 7.94434 33.3327 8.94434V12.2777C33.3327 13.2777 33.9993 13.9443 34.9993 13.9443C35.9993 13.9443 36.666 13.2777 36.666 12.2777V8.94434C36.666 6.111 34.4993 3.94434 31.666 3.94434Z"
                fill={color}/>
            <path
                d="M34.9993 27.2773C33.9993 27.2773 33.3327 27.944 33.3327 28.944V32.2773C33.3327 33.2773 32.666 33.944 31.666 33.944H28.3327C27.3327 33.944 26.666 34.6107 26.666 35.6107C26.666 36.6107 27.3327 37.2773 28.3327 37.2773H31.666C34.4993 37.2773 36.666 35.1107 36.666 32.2773V28.944C36.666 27.944 35.9993 27.2773 34.9993 27.2773Z"
                fill={color}/>
            <path
                d="M11.6673 33.944H8.33398C7.33398 33.944 6.66732 33.2773 6.66732 32.2773V28.944C6.66732 27.944 6.00065 27.2773 5.00065 27.2773C4.00065 27.2773 3.33398 27.944 3.33398 28.944V32.2773C3.33398 35.1107 5.50065 37.2773 8.33398 37.2773H11.6673C12.6673 37.2773 13.334 36.6107 13.334 35.6107C13.334 34.6107 12.6673 33.944 11.6673 33.944Z"
                fill={color}/>
            <path
                d="M10 13.944C10 14.944 10.6667 15.6107 11.6667 15.6107H25C26 15.6107 26.6667 14.944 26.6667 13.944C26.6667 12.944 26 12.2773 25 12.2773H11.6667C10.6667 12.2773 10 12.944 10 13.944Z"
                fill={color}/>
            <path
                d="M10 20.611C10 21.611 10.6667 22.2777 11.6667 22.2777H28.3333C29.3333 22.2777 30 21.611 30 20.611C30 19.611 29.3333 18.9443 28.3333 18.9443H11.6667C10.6667 18.9443 10 19.611 10 20.611Z"
                fill={color}/>
            <path
                d="M21.6667 25.6108H11.6667C10.6667 25.6108 10 26.2775 10 27.2775C10 28.2775 10.6667 28.9442 11.6667 28.9442H21.6667C22.6667 28.9442 23.3333 28.2775 23.3333 27.2775C23.3333 26.2775 22.6667 25.6108 21.6667 25.6108Z"
                fill={color}/>
        </svg>
    );
}
export default AIVoices;