import React from "react";

interface IconCatalogProps {
  color?: string;
  height?: number;
  width?: number;
}

const IconCatalog: React.FC<IconCatalogProps> = ({ color = "#183B4D", height = 24 , width = 24}) => (
    <svg width={width} height={height} viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 2.69L15 7.19V15H13V9H7V15H5V7.19L10 2.69ZM10 0L0 9H3V17H9V11H11V17H17V9H20L10 0Z" fill={color}/>
    </svg>
);

export default IconCatalog;
