import React from "react";
import {Flex,Text} from "@chakra-ui/react";
import OptionModalHome  from "../../atoms/OptionModalHome/OptionModalHome.tsx";
import {ModalOption} from "../HomePage/PageHome.tsx";
import { useTranslation } from "react-i18next";
import { ModalCustom } from '@canaia/ui-kit';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    optionTitle: string;
    options: ModalOption[];
}
const ModalOptionsHome : React.FC<Props> = ({isOpen,onClose, optionTitle, options}) => {
    const { t } = useTranslation();

    const ModalProps =  {
        backgroundColorModal:'rgba(252, 184, 171, .5)',
        title: "",
        disableOverlay: true,
        maxWidth: '507px',
        children: (
            <Flex direction='column'>
                <Text fontSize='1.5rem' fontFamily='Sansation-Bold' fontWeight='700' textAlign='left'>{t(optionTitle)}</Text>
                <Flex direction='row' justifyContent='space-between' margin={'1.5rem 0 1rem 0'}>
                    {options.map((option, index) => (  // Traverse the array directly
                        <OptionModalHome
                            key={index}
                            image={option.icon}
                            title={option.title}
                            description={option.description}
                            link={option.link}
                        />
                    ))}
                </Flex>
            </Flex>
        )
    }
    return (
        <div className="wrapper-modal-options-home">
            <ModalCustom  {...ModalProps}  isOpen={isOpen} onClose={onClose}/>
        </div>
    );
}
export default ModalOptionsHome;